import { Injectable } from '@angular/core';
import * as sanitizeHtml from 'sanitize-html';

@Injectable({
  providedIn: 'root'
})
export class SanitizeService {

  constructor() { }

  safenInput(unsafeInput: string) {
    // this returns an input string with the following html entities
    // removed and plain symbols returned
    // used in forum posts & comments - before the input is sanitized this function is ran
    // because quill outputs symbols as entities and they are being stripped by sanitize-html
    // < and > symbols are being removed completely
    const safeInput = unsafeInput.replace(/&amp;/g, `&`)
                                  .replace(/&lt;/g, ``)
                                  .replace(/&gt;/g, ``)
                                  .replace(/&quot;/g, `"`)
                                  .replace(/&#39;/g, `'`);
    return safeInput;
  }

  sanitizeUserInput(input: string) : string {
    const cleanInput =
    sanitizeHtml(input, {
        // the allowed attributes are needed because we add these tags inside the quill editor
        allowedAttributes: {
          'a': ['href', 'target', 'tabindex', 'rel']
        },
        textFilter: function(text) {
            return text
                .replaceAll("&amp;", "&")
                .replaceAll("&lt;", "")
                .replaceAll("&gt;", "");
          }
      });
    return cleanInput;
  }
}
