<p-dropdown
  #downloadComponent
  [options]="items"
  dropdownIcon="pi pi-angle-down"
  class="p-dropdown--only-icon"
  placeholder="select"
  (onChange)="onChange($event)"
  [ariaLabel]="'Export dropdown'"
>
    <ng-template let-item pTemplate="item">
        <div>
          <img *ngIf="item.img" [src]="item.img" />
          <div>{{ item.name }}</div>
        </div>
    </ng-template>
</p-dropdown>