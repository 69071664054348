<div class="login-page" [ngClass]="{'initial-loading': showLogin}">
  <div class="login-loader">
    <div class="loader-inner">
        <div class="loader-image">
            <img src="assets/images/carrot-logo-black-cropped.png" alt="Carrot loader indicator...">
        </div>
        <div class="loader-progress"></div>
    </div>
  </div>
  <div class="login-widget" [ngClass]="{'loading': loaderShown}">
    <div class="login-widget-content">
      <div class="login-widget-branding">
        <img [src]="competitionLogo" [alt]="competitionLogoAltText">
        <div class="loader">
          <div class="loader-inner"></div>
        </div>
      </div>
      
      <div class="login-form-wrap" *ngIf="!hideReg && !ssoAuthDisplayService.ssoGenericScreen.visible">
        <h1 class="title">Register</h1>
        <p class="subtitle">To participate, please Register or click Login if you already have an account</p>
        <div class="action-failed" *ngIf="showErrorMessage">
          <p>{{ errorMessage }}</p>
        </div>
        <div class="input-wrap">
          <label for="user-first-name" class="input-label">
            <input
              type="text"
              id="user-first-name"
              class="input-text"
              #UserFirstNameId="ngModel"
              [(ngModel)]="userFirstName"
              (keydown)="keyDownHandler($event)"
              maxlength="256"
              [ngClass]="{
                'errored': userFirstName?.length > 50 || (userFirstName?.length < 1 && UserFirstNameId.touched),
                'errored-soft': userFirstName?.length > 50,
                'errored-real': userFirstName?.length < 1 && UserFirstNameId.touched
              }"
              aria-label="Enter First Name"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{
                'filled': userFirstName,
                'errored': userFirstName?.length > 50 || (userFirstName?.length < 1 && UserFirstNameId.touched)
              }"
            >
              First Name
            </span>
            <span *ngIf="userFirstName?.length > 50 || (userFirstName?.length < 1 && UserFirstNameId.touched)" class="error-field">
              <span class="error-message error-message-soft" *ngIf="userFirstName?.length > 50">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">You have reached the maximum allowed characters</span>
              </span>
              <span class="error-message" *ngIf="userFirstName?.length < 1 && UserFirstNameId.touched">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">First Name is required</span>
              </span>
            </span>
          </label>
          <label for="user-last-name" class="input-label">
            <input
              type="text"
              id="user-last-name"
              class="input-text"
              #userLastNameId="ngModel"
              [(ngModel)]="userLastName"
              (keydown)="keyDownHandler($event)"
              maxlength="256"
              [ngClass]="{
                'errored': userLastName?.length > 50 || (userLastName?.length < 1 && userLastNameId.touched),
                'errored-soft': userLastName?.length > 50,
                'errored-real': userLastName?.length < 1 && userLastNameId.touched
              }"
              aria-label="Enter Last Name"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{
                'filled': userLastName,
                'errored': userLastName?.length > 50 || (userLastName?.length < 1 && userLastNameId.touched)
              }"
            >
              Last Name
            </span>
            <span *ngIf="userLastName?.length > 50 || (userLastName?.length < 1 && userLastNameId.touched)" class="error-field">
              <span class="error-message error-message-soft" *ngIf="userLastName?.length > 50">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">You have reached the maximum allowed characters</span>
              </span>
              <span class="error-message" *ngIf="userLastName?.length < 1 && userLastNameId.touched">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">Last Name is required</span>
              </span>
            </span>
          </label>
          <label for="user-email" class="input-label">
            <input
              type="text"
              id="user-email"
              class="input-text"
              #userEmailId="ngModel"
              [(ngModel)]="userEmail"
              (keydown)="keyDownHandler($event)"
              (keyup)="userEmail = userEmail.trim()"
              maxlength="256"
              [ngClass]="{
                'errored': (!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched),
                'errored-real': (!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched)
              }"
              aria-label="Enter email"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{ 
                'filled': userEmail,
                'errored': (!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched)
              }"
            >
              Email
            </span>
            <span *ngIf="(!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched)" class="error-field">
              <span class="error-message" *ngIf="(!userEmail?.match(emailRegex) && userEmail?.length > 0)">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">Please enter a valid email address.</span>
              </span>
              <span class="error-message" *ngIf="userEmail?.length < 1 && userEmailId.touched">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">Please enter a valid email address</span>
              </span>
            </span>
          </label>
          <label for="user-password" class="input-label">
            <input
              [type]="passwordInputType"
              #passwordInput="ngModel"
              id="user-password"
              class="input-text"
              #userPasswordId="ngModel"
              [(ngModel)]="userPassword"
              (keydown)="keyDownHandler($event)"
              (validationCountUpdated)="checkPasswordValidity($event)"
              maxlength="256"
              reqired
              passwordCheck
              [validationList]="validationList"
              [ngClass]="{
                'errored': (!isPasswordValid && userPassword?.length > 0) || (userPassword?.length < 1 && userPasswordId.touched),
                'errored-real': (!isPasswordValid && userPassword?.length > 0) || (userPassword?.length < 1 && userPasswordId.touched)
              }"
              aria-label="Enter password"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{
                'filled': userPassword,
                'errored': (!isPasswordValid && userPassword?.length > 0) || (userPassword?.length < 1 && userPasswordId.touched)
              }"
            >
              Password
            </span>
            <button
              class="icon icon-right material-icons-outlined show-pass-button"
              (click)="showPasswordButtonClicked()"
              [ngClass]="{'hide-pass': passwordButtonTooltip === 'Hide password'}"
              [pTooltip]="passwordButtonTooltip"
              [attr.aria-label]="passwordButtonTooltip === 'Hide password' ? 'Hide password' : 'Show password'"
            >
              visibility
            </button>
          </label>
          <div class="validation-rules-wrap" *ngIf="userPasswordId.touched || userPassword.length > 0">
            <div class="password-requirements">
              <span class="txt">Password Requirements:</span>
              <ul class="password-requirements-list">
                <ng-container *ngFor="let validation of validationList">
                  <li [ngClass]="validation.count >= validation.expCount ? 'matched' : 'not-matched'">
                    <span class="rule-label">{{ validation.name }}</span>
                    <span class="icon material-icons" *ngIf="validation.count >= validation.expCount">done</span>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="button-wrap">
          <button
            class="button button-primary button-login"
            (click)="onSubmit()"
            [disabled]="
              (this.userFirstName.length > 50 || this.userFirstName.length < 1) ||
              (this.userLastName.length > 50 || this.userLastName.length < 1) ||
              !this.userEmail.match(emailRegex) ||
              (!this.passwordInput.valid || this.userPassword.length < 1) ||
              disabledRegButton
            "
          >
            Register
          </button>
        </div>
        <div class="bottom-button-wrap">
          <span class="text">Have an account?</span>
          <a routerLink="/login" class="button-forgotten-pass">Login!</a>
        </div>
        <div class="bottom-back-wrap">
          <a [href]="feBaseUrl">Back to Homepage</a>
        </div>
      </div>

      <app-auth-generic-screen></app-auth-generic-screen>
    </div>
  </div>
</div>
