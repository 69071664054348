import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "app-export-data",
  templateUrl: "./export-data.component.html",
  styleUrls: ["./export-data.component.scss"],
})
export class ExportDataComponent implements OnChanges {
  @Input() showZipDownload = false;

  @ViewChild('downloadComponent') downloadComponent: Dropdown

  @Output() download = new EventEmitter<boolean>();

  items = [
    { name: "Excel", img: "./../../assets/fonts/excel.svg", code: "xlsx" },
    { name: "CSV", img: "./../../assets/fonts/csv.svg", code: "csv" },
  ];

  constructor() {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showZipDownload']?.currentValue) {
      this.items.push({
        name: "ZIP", img: "./../../assets/fonts/zip.svg", code: "zip"
      });
    }
  }

  onChange(event: any): void {
    this.download.emit(event.value)
    this.downloadComponent.selectedOption = null;
  }
}
